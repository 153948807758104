import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { navigate } from 'gatsby';
import Layout from '../../components/layout';
import { H15 } from '../../components/Texts';
import GetInTouchForm from '../../components/GetInTouchForm';

const Agroactive2022 = () => {

  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    fetch('https://submit-form.com/c8TMIb0l', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formProps),
    }).then(() => {
      navigate(`${formProps.role}`);
    });
  };

  return (
    <Layout>
      <div className="flex items-center mx-auto max-w-7xl">
        <div className="px-4 py-6 lg:pt-16 lg:pl-32 lg:w-7/12 mb-16 lg:flex-shrink-0">
          <div className='lg:hidden flex flex-row items-center mb-2'>
            <img src='/images/agroactiva.png' width='220px' height='67px' alt='agroactiva' className='mr-2'/>
            <img src='/images/agro_imago.png' alt='agroactiva' className='mr-2' width='26px' height='19px'/>
          </div>
          <p className="mb-3 text-green-700">
            {intl.formatMessage({ id: 'agroActiveTitle' })}
          </p>
          <H15 className="mb-3">
            {intl.formatMessage({ id: 'agroActiveSubTitle' })}
          </H15>
          <p className="mb-6">
            {intl.formatMessage({ id: 'agroActiveBody' })}
          </p>
          <GetInTouchForm onSubmit={handleSubmit} />
        </div>
        <div className="hidden lg:flex flex-col items-center justify-center">
          <img src='/images/AA_Logo2022Big.png' alt="contact" />
        </div>
      </div>
    </Layout>
  );
};

export default Agroactive2022;
